import './vendor/jquery/1.11.1/jquery.js';

import { amplify } from './acs-amplify.js';
window.amplify = amplify;

if (typeof acs === 'undefined') {
  acs = {};
}

if (typeof acs.ui === 'undefined') {
  acs.ui = {};
}

import './requests.js'; // import for the side effect of defining amplify request types
import './acs-sso.js';
import './acs-skipnav.js';
import './acs-user-menu.js';
import './acs-search.js';
import './acs-bootstrap-dropdown.js';

if (!!document.querySelector('div#search')) {
  acs.search.init();
}

// fetch user data
function initializeLoginMenu() {
  // render the login menu
  acs.ui.loginMenu.init();

  // render mobile profile info into menu
  const myMenu = document.getElementById('my-menu');
  const navbarIcon = document.querySelector('.navbar-toggle.icon');
  if (myMenu) {
    if (navbarIcon) {
      navbarIcon.id = 'mainSiteProfileInfo';
      navbarIcon.classList.add('mm-list');
      navbarIcon.style.display = 'none';
    }
    myMenu.classList.add('loggedout');
  }

  const myAccountMenu = document.querySelector('#myAccountMenu');
  if (myAccountMenu) {
    const myAccountMenuClone = myAccountMenu.cloneNode(true);
    myAccountMenuClone.id = 'mainSiteProfileInfo';
    myAccountMenuClone.classList.add('mm-list');
    const mainSiteProfileInfo = document.querySelector('#mainSiteProfileInfo');
    if (mainSiteProfileInfo) {
      mainSiteProfileInfo.append(myAccountMenuClone);
      const heading = mainSiteProfileInfo.querySelector('.heading');
      if (heading) {
        heading.textContent = `${acs.user.firstname} ${acs.user.lastname}`;
        heading.classList.add('heading-height');
      }
    }
  }
}

// hack check to see if script needs to run after DOMContentLoaded
if (acs.assetDomain.indexOf("cloud") >= 0) {
  acs.sso.init().always(function () {
    initializeLoginMenu();
  });
} else {
  document.addEventListener("DOMContentLoaded", function () {
    acs.sso.init().always(function () {
      initializeLoginMenu();
    });
  });
}